import React, { useState, useEffect } from "react"
import { db } from "../utils/firebase"
import Layout from "../components/layout/layout"
import SiteMetadata from "../components/helpers/siteMetadata"

import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import Table from "@material-ui/core/Table"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import Paper from "@material-ui/core/Paper"
import styled from "styled-components"

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  AreaChart,
  Area,
} from "recharts"

const StyledPaper = styled(Paper)`
  background-color: #424242;
  border-radius: 5px;
  padding: 15px;
  color: white;
`

const StyledTableContainer = styled(TableContainer)`
  background-color: #424242;
  border-radius: 5px;
  margin-top: 50px;
  margin-bottom: 10px;
  table {
    min-width: 650;
  }
  table td,
  table th {
    border-color: #515151;
    color: white;
    font-family: "Segoe UI";
    font-size: 1rem;
  }
`

export default function AdminDeVerdad() {
  const siteName = SiteMetadata().title
  const [challenges, setChallenges] = useState([
    { completado: 0, nombre: "Nombre", id: 1, pistas: 0, soluciones: 0 },
    { completado: 0, nombre: "Nombre", id: 2, pistas: 0, soluciones: 0 },
    { completado: 0, nombre: "Nombre", id: 3, pistas: 0, soluciones: 0 },
  ])

  useEffect(() => {
    db.collection(siteName).onSnapshot(querySnapshot => {
      const docs = []
      querySnapshot.forEach(doc => {
        docs.push({ ...doc.data(), id: doc.id })
      })
      setChallenges(docs)
    })
  }, [siteName])

  return (
    <Layout>
      <Container>
        <Grid container justify="center">
          <Grid item lg={12}>
            <StyledTableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Enigma</TableCell>
                    <TableCell>Completado</TableCell>
                    <TableCell>Pistas Usadas</TableCell>
                    <TableCell>Soluciones Usadas</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {challenges.map(doc => (
                    <TableRow key={doc.id}>
                      <TableCell>{doc.id}</TableCell>
                      <TableCell>{doc.completado}</TableCell>
                      <TableCell>{doc.pistas}</TableCell>
                      <TableCell>{doc.soluciones}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </StyledTableContainer>
          </Grid>
          <Grid item lg={6}>
            <StyledPaper style={{ marginRight: "5px" }}>
              <BarChart
                width={500}
                height={300}
                data={challenges}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="id" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="completado" fill="#fcba03" />
                <Bar dataKey="pistas" fill="#4a44bd" />
                <Bar dataKey="soluciones" fill="#6dd16d" />
              </BarChart>
            </StyledPaper>
          </Grid>
          <Grid item lg={6}>
            <StyledPaper style={{ marginLeft: "5px" }}>
              <AreaChart
                width={500}
                height={300}
                data={challenges}
                margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
              >
                <defs>
                  <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="#fcba03" stopOpacity={0.8} />
                    <stop offset="95%" stopColor="#fcba03" stopOpacity={0} />
                  </linearGradient>
                  <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="#4a44bd" stopOpacity={0.8} />
                    <stop offset="95%" stopColor="#4a44bd" stopOpacity={0} />
                  </linearGradient>
                  <linearGradient id="colorDv" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="#6dd16d" stopOpacity={0.8} />
                    <stop offset="95%" stopColor="#6dd16d" stopOpacity={0} />
                  </linearGradient>
                </defs>
                <XAxis dataKey="id" />
                <YAxis />
                <CartesianGrid strokeDasharray="3 3" />
                <Tooltip />
                <Area
                  type="monotone"
                  dataKey="completado"
                  stroke="#8884d8"
                  fillOpacity={1}
                  fill="url(#colorUv)"
                />
                <Area
                  type="monotone"
                  dataKey="pistas"
                  stroke="#82ca9d"
                  fillOpacity={1}
                  fill="url(#colorPv)"
                />
                <Area
                  type="monotone"
                  dataKey="soluciones"
                  stroke="#82ca9d"
                  fillOpacity={1}
                  fill="url(#colorDv)"
                />
              </AreaChart>
            </StyledPaper>
          </Grid>
        </Grid>
      </Container>
    </Layout>
  )
}
